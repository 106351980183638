import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from "@material-ui/core/Box";
import Grid from '@material-ui/core/Grid';
import { IconButton, Link, Typography } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: 800,
        margin: "0 auto",

    },
    paper: {
        padding: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    li: {
        marginTop: 8
    },
    link: {
        flex: 1,
        marginBottom: theme.spacing(2)
    },
    linkBox: {
        padding: theme.spacing(1),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    linkContainer: {
        display: "flex"
    }
}));

export default function App() {
    const classes = useStyles();

    return (
        <Paper className={classes.root} elevation="3">
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <img src="head_b.jpg" width="800" />
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper} elevation="2">
                        <Typography variant="h4" component="h1">Deutsche Bücher - Německé knihy</Typography>
                        <Typography variant="body1">
                            na němčinu s úsměvem
              </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Paper className={classes.paper} elevation="2">
                        <Typography variant="h5" component="h2">Odkazy na náš nový web:</Typography>
                        <div className={classes.linkContainer}>
                            <Link className={classes.link} href="https://www.librilatini.cz/latina/eshop/33-1-Cizi-jazyky/17-3-zjednodusena-cetba">
                                <Box border={1} borderColor="primary.main" borderRadius={8} className={classes.linkBox}>
                                    <Typography variant="button">
                                        Zjednodušená četba v němčině
                            </Typography>
                                    <IconButton>
                                        <ArrowForwardIcon />
                                    </IconButton>
                                </Box>
                            </Link>
                        </div>
                        <div className={classes.linkContainer}>
                            <Link className={classes.link} href="https://www.librilatini.cz/latina/eshop/33-1-Cizi-jazyky/19-3-nemecka-beletrie-pro-dospele">
                                <Box border={1} borderColor="primary.main" borderRadius={8} className={classes.linkBox}>
                                    <Typography variant="button">
                                        Beletrie v němčině
                            </Typography>
                                    <IconButton>
                                        <ArrowForwardIcon />
                                    </IconButton>
                                </Box>
                            </Link>
                        </div>
                        <div className={classes.linkContainer}>
                            <Link className={classes.link} href="https://www.librilatini.cz/latina/eshop/33-1-Cizi-jazyky/16-3-dvojjazycne">
                                <Box border={1} borderColor="primary.main" borderRadius={8} className={classes.linkBox}>
                                    <Typography variant="button">
                                        Dvojjazyčná četba nejen v němčině
                            </Typography>
                                    <IconButton>
                                        <ArrowForwardIcon />
                                    </IconButton>
                                </Box>
                            </Link>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Paper className={classes.paper} elevation="2">
                        <Typography variant="h5" component="h2">Proč nakupovat právě u nás?</Typography>
                        <ul>
                            <li className={classes.li}>většinu knih máme skladem</li>
                            <li className={classes.li}>díky dobrým vztahům s německými distributory přivezeme téměř cokoli, co vyjde v Německu</li>
                            <li className={classes.li}>zajistíme individuální objednávky publikací, které v eshopu nenabízíme</li>
                            <li className={classes.li}>reagujeme na změny Eura nejen směrem nahoru, ale také dolů</li>
                            <li className={classes.li}>školy a instituce odebírají zboží na fakturu</li>
                            <li className={classes.li}>stálým zákazníkům nabízíme slevové kupony</li>
                        </ul>
                    </Paper>
                </Grid>
            </Grid>
        </Paper>
    );
}
